import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import gwi1 from '../../assets/img-gwi-1.png';
import gwi2 from '../../assets/img-gwi-2.png';
import gwi3 from '../../assets/img-gwi-3.png';
import gwi4 from '../../assets/img-gwi-4.png';
import gwi5 from '../../assets/img-gwi-5.png';
import gwi6 from '../../assets/img-gwi-6.png';
import { useTranslation } from "react-i18next";



export const Gwi = () =>{
    const { t } = useTranslation()
    return(
        <> 
        <Container className="projectBody ">
        <Row xs='1' >
            <Col className="projectInfo" lg='5' >
            <h3>GWI</h3>
            <p>
            {t("gwi_1parragraph")}
             <br/><br/>
             {t("gwi_2parragraph")}
            <br/><br/>
            {t("gwi_3parragraph")} <a href="https://youtu.be/L-QqMhBsnKQ" target='_blank' rel="noreferrer">Shark Tank México</a>. 
            </p>
            <div>
            <h4>{t("project_role")}</h4>
            <p>{t("uxui_design")} &nbsp; &nbsp; &mdash; &nbsp; &nbsp; {t("motion_graphics")}</p>
            <div>
            <a href='https://play.google.com/store/apps/details?id=com.gwialert.gwi&hl=es_US' target="_blank" rel="noreferrer">{t("gwi_download")} &nbsp;<i class="bi bi-arrow-up-right"></i></a>
            </div>
            </div>

            </Col>
            <Col className="projectDetails" style={{backgroundColor: '#57ccea'}} lg='7'>
            
            <img src={gwi1} style={{width:'100%'}} alt="Gwi app uso"></img>
            <img src={gwi2} style={{width:'100%'}} alt="Gwi before and after"></img>
            <img src={gwi3} style={{width:'100%'}} alt="Gwi Information Architecture"></img>
            <img src={gwi4} style={{width:'100%'}} alt="Gwi low, medium and high fidelity wireframes and iconography"></img>
            <img src={gwi5} style={{width:'100%'}} alt="Gwi final frames"></img>
            <img src={gwi6} style={{width:'100%'}} alt="Gwi holidays pins"></img>
        
            <iframe width='100%' height='56.25%' style={{margin:'0'}} src="https://www.youtube-nocookie.com/embed/lQ5wJ1nAGsU?rel=0" title="YouTube video player" frameborder="0" allow="autoplay; encrypted-media; showinfo" allowfullscreen></iframe>
            <div style={{width:'100%', color:'#FAFAFA', padding:'.5rem'}}><p style={{fontSize:'calc(.5rem + .4vw)', margin:'0'}}>MOTION GRAPHICS</p></div>
            
            </Col>
        </Row>
        </Container>
        </>
    )
}

export default Gwi;