import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import opm1 from '../../assets/img-opm-1.png';
import opm2 from '../../assets/img-opm-2.png';
import opm3 from '../../assets/img-opm-3.png';
import opm4 from '../../assets/img-opm-4.png';
import { useTranslation } from "react-i18next";




export const Opm = () =>{
    const { t } = useTranslation()
    return(
        <> 
        <Container className="projectBody ">
        <Row xs='1' >
            <Col className="projectInfo" lg='5' >
            <h3>OPM</h3>
            <p>
            {t("opm_1parragraph")}
            <br/><br/>
            {t("opm_2parragraph")}
            </p>
            <div>
            <h4>{t("project_role")}</h4>
            <p>D{t("uxui_design")} &nbsp; &nbsp; &mdash; &nbsp; &nbsp; {t("frontend_development")}</p>
            <div>
            </div>
            </div>

            </Col>
            <Col className="projectDetails"  lg='7'>
            <img src={opm1} style={{width:'100%'}} alt="OPM web example"></img>
            <img src={opm2} style={{width:'100%'}} alt="OPM web example"></img>
            <img src={opm3} style={{width:'100%'}} alt="OPM web example"></img>
            <img src={opm4} style={{width:'100%'}} alt="OPM web example"></img>
                    
            </Col>
        </Row>
        </Container>
        </>
    )
}

export default Opm;