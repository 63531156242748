import React from "react";
import '../App.css';
import linkedin from '../assets/linkedin.svg';
import dribble from '../assets/dribble.svg';
import readcv from '../assets/readcv.svg';
import { useTranslation } from "react-i18next";

export const Footer = () =>{
    const { t } = useTranslation()
    return(
        <> 
       <div className="myfooter">
       <div className="col footertext" style={{padding:'6rem 0 6rem 0'}}>
        <h1>{t("footer_frase")}</h1>
        <p><a href = "mailto: arantxa.rmz.95@gmail.com">{t("footer_a_contact")}</a>{t("footer_ideas")} &#10024;</p>
        <a href="https://linkedin.com/in/arantxa-ramirez"  target="_blank" rel="noreferrer"><img src={linkedin} style={{width:'2rem'}} alt='LinkedIn' className='social'></img></a>
        <a href="https://dribbble.com/arantxarmz"  target="_blank" rel="noreferrer"><img src={dribble} style={{width:'2rem'}} alt='Dribble' className='social'></img></a>
        <a href='https://read.cv/arantxa.ramirez' target="_blank" rel="noreferrer"><img src={readcv} style={{width:'2rem'}} alt='Read.CV' className='social' ></img></a>
        </div>
       </div>


        </>
    )
}

export default Footer;