import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import useLocalStorage from 'use-local-storage';

import { useTranslation } from "react-i18next";

import NavBar from './components/navbar';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";  
import {Home} from './Pages/home';
import {About} from './Pages/about';
import {Gwi} from './Pages/Projects/gwi';
import {GreenMister} from './Pages/Projects/greenmister';
import {Opm} from './Pages/Projects/opm'
import {Entrenarme} from './Pages/Projects/entrenarme';
import Footer from './components/footer';

const languages = [
  {
    code: 'en',
    name:'English'
  },
  {
    code:'es',
    name: 'Español'
  }
];




function App() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const switchTheme = (props) => {
    const newTheme = theme === 'light'? 'dark' : 'light'
    setTheme (newTheme) ;
  }



  return (
    
    <div className="App" data-theme={theme}>
    <header className='App-header'>
   <div className=''>
    <NavBar themeSelect={switchTheme} infoTheme={theme} lngSelect={languages} />
    </div>
   </header>
   <div className='App-body'>
   <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/projects/Gwi' element={<Gwi/>} />
      <Route path='/projects/GreenMister' element={<GreenMister/>} />
      <Route path='/projects/OPM' element={<Opm/>} />
      <Route path='/projects/Entrenarme' element={<Entrenarme/>} />
    </Routes>
    </div>
    <Footer/>

    </div>

    
  );
}

export default App;
