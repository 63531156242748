import React from "react";
import '../App.css';
import image1 from '../assets/img-1.png'
import SimpleSlider from "../components/carousel";
import { useTranslation } from "react-i18next";

export const Home = () =>{
    const { t } = useTranslation()
    return(
        <> 
        <div className='container start'>
            <div className="row">
                <div className="col intror">
                <img src={image1} width='659' height='678' className="image-1" alt="Portada"/> 
                </div>
                <div className="col d-flex align-items-start flex-column intro">
                    <h1>{t("home_designer")}</h1>
                    <p>{t("home_hello")}</p>
                </div>
            </div>
            <div className="projectsslider">
            <SimpleSlider/>

            </div>
        </div>

        </>
    )
}

export default Home;