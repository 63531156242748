import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import gm1 from '../../assets/img-gm-1.png';
import gm2 from '../../assets/img-gm-2.png';
import gm3 from '../../assets/img-gm-3.png';
import gm4 from '../../assets/img-gm-4.png';
import gm5 from '../../assets/img-gm-5.png';
import gm6 from '../../assets/img-gm-6.png';
import gm7 from '../../assets/img-gm-7.png';
import gm8 from '../../assets/img-gm-8.png';
import { useTranslation } from "react-i18next";


export const GreenMister = () =>{
    const { t } = useTranslation()
    return(
        <> 
        <Container className="projectBody ">
        <Row xs='1' >
            <Col className="projectInfo" lg='5' >
            <h3>GREEN MISTER</h3>
            <p>
            {t("gm_1parragraph")}
             <br/><br/>
             {t("gm_2parragraph")}
             <br/><br/>
             {t("gm_3parragraph")}
             </p>
            <div>
            <h4>{t("project_role")}</h4>
            <p>{t("uxui_design")}</p>
            <div>
            </div>
            </div>

            </Col>
            <Col className="projectDetails" lg='7'>
            
            <img src={gm1} style={{width:'100%'}} alt="Green Mister app use"></img>
            <img src={gm2} style={{width:'100%'}} alt="Green Mister before and after"></img>
            <img src={gm3} style={{width:'100%'}} alt="Green Mister color sheet light and dark theme"></img>
            <img src={gm4} style={{width:'100%'}} alt="Green Mister componentes"></img>
            <img src={gm5} style={{width:'100%'}} alt="Green Mister application final frames"></img>
            <img src={gm6} style={{width:'100%'}} alt="Green Mister web home page"></img>
            <img src={gm7} style={{width:'100%'}} alt="Green Mister web about page"></img>
            <img src={gm8} style={{width:'100%'}} alt="Green Mister web products page"></img>
                    
            </Col>
        </Row>
        </Container>
        </>
    )
}

export default GreenMister;