import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ent1 from '../../assets/img-ent-1.png';
import ent2 from '../../assets/icon-ent-1.gif';
import ent3 from '../../assets/icon-ent-2.gif';
import ent4 from '../../assets/icon-ent-3.gif';
import ent5 from '../../assets/icon-ent-4.gif';
import ent6 from '../../assets/icon-ent-5.gif';
import { useTranslation } from "react-i18next";




export const Entrenarme = () =>{
    const { t } = useTranslation()
    return(
        <> 
        <Container className="projectBody ">
        <Row xs='1' >
            <Col className="projectInfo" lg='5' >
            <h3>ENTRENARME</h3>
            <p>
            {t("ent_1parragraph")}
             <br/><br/>
             {t("ent_2parragraph")}
             <br/><br/>
             {t("ent_3parragraph")}
             </p>
            <div>
            <h4>{t("project_role")}</h4>
            <p>{t("uxui_design")} &nbsp; &nbsp; &mdash; &nbsp; &nbsp; {t("ui_animation")}</p>
            <div>
            <a href='https://apps.apple.com/mx/app/entrenarme/id1473823049' target="_blank" rel="noreferrer">Descargar Entrenarme &nbsp;<i class="bi bi-arrow-up-right"></i></a>
            </div>
            </div>

            </Col>
            
            <Col className="projectDetails" style={{backgroundColor: '#843F90'}} lg='7'>
            <img src={ent1} style={{width:'100%'}} alt="Entrenarme app uso"></img>
            <Container style={{padding:'0'}}>
            <Row style={{ margin:'10vw 0 0 0', height:'auto', justifyContent: 'center'}} xs='2'>
            <Col style={{}} >
            <img src={ent2} style={{width:'17vw'}} alt="Entrenarme app uso"></img>
            <div style={{width:'100%', color:'#FAFAFA', padding:'.5rem', marginTop:'.9rem'}}><p style={{fontSize:'calc(.5rem + .4vw)', margin:'0'}}>START BUTTON</p></div>
            </Col>
            <Col style={{}}>
            <img src={ent3} style={{width:'17vw'}} alt="Entrenarme app uso"></img>
            <div style={{width:'100%', color:'#FAFAFA', padding:'.5rem', marginTop:'.9rem'}}><p style={{fontSize:'calc(.5rem + .4vw)', margin:'0'}}>SPINNER</p></div>
            </Col>
            </Row>
            <Row style={{ margin:'5vw 0 0 0', height:'auto', justifyContent: 'center'}}>
            <img src={ent4} style={{width:'90%', marginLeft:'5vw'}} alt="Entrenarme app uso"></img>
            <div style={{width:'100%', color:'#FAFAFA', padding:'.5rem'}}><p style={{fontSize:'calc(.5rem + .4vw)', margin:'0'}}>LOGO</p></div>
            </Row>
            <Row style={{ margin:'5vw 0 5vw 0', height:'auto', justifyContent: 'center'}} xs='2'>
            <Col style={{}} >
            <img src={ent5} style={{width:'20vw'}} alt="Entrenarme app uso"></img>
            <div style={{width:'100%', color:'#FAFAFA', padding:'.5rem', marginTop:'.9rem'}}><p style={{fontSize:'calc(.5rem + .4vw)', margin:'0'}}>NOTIFICATION</p></div>
            </Col>
            <Col style={{}}>
            <img src={ent6} style={{width:'20vw'}} alt="Entrenarme app uso"></img>
            <div style={{width:'100%', color:'#FAFAFA', padding:'.5rem', marginTop:'.9rem'}}><p style={{fontSize:'calc(.5rem + .4vw)', margin:'0'}}>PLAYER VS PLAYER</p></div>
            </Col>
            </Row>

            </Container>
            </Col>
        </Row>
        </Container>
        </>
    )
}

export default Entrenarme;