import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from "react-i18next";
import pic from '../assets/ToonMe2.PNG';

export const About = () =>{
    const { t } = useTranslation()
    return(
        <> 
        <Container className="about" >
            <Row xs='1' style={{margin:'2rem'}}>
                <Col style={{  alignSelf:'center'}} md='6'>
                <img src={pic} style={{width:'80%', borderRadius:'2vw'}} alt="Profile"></img>

                </Col>
                <Col className="aboutText" style={{ marginTop:'1rem'}} md='6'>
                    <h1>Arantxa Ramírez</h1>
                    <p className="parrafo">
                        {t("about_1parragraph")}
                    <br/><br/>
                    {t("about_2parragraph")}
                    </p>
               
                </Col>
            </Row>
            <Row  style={{margin:'2rem'}}>
            <Col lg='6' >
                
            </Col>
            <Col lg='6' style={{fontSize:'medium'}}>
            <h4 >{t("experience")}</h4>
                <Container className="experience" >
                    <Row>
                    <Col className='periodo' xs='12' md='2'>
                    <p style={{fontSize:'small'}}>{t("2019_present")}</p>
                    </Col>
                    <Col className="puesto" md='6'>
                    <p >{t("uxui_designer")}, {t("animator")}</p>
                    </Col>
                    <Col className="empresa" md='4'>
                    <p >Freelance</p>
                    </Col>
                    </Row>
                </Container>

                <Container className="experience">
                    <Row>
                    <Col className='periodo' md='2'>
                    <p >{t("jul20_dec22")}</p>
                    </Col>
                    <Col className="puesto" md='6'>
                    <p>{t("ui_designer")}</p>
                    </Col>
                    <Col className="empresa" md='4'>
                    <p>Crowdhealth</p>
                    </Col>
                    </Row>
                </Container>

                <Container className="experience">
                    <Row>
                    <Col className='periodo' md='2'>
                    <p >{t("nov19_jun20")}</p>
                    </Col>
                    <Col className="puesto" md='6'>
                    <p >{t("uxui_designer")}, {t("animator")}</p>
                    </Col>
                    <Col className="empresa" md='4'>
                    <p >GWI Alert</p>
                    </Col>
                    </Row>
                </Container>
                <Container className="experience">
                    <Row>
                    <Col className='periodo' md='2'>
                    <p >{t("aug18_dec19")}</p>
                    </Col>
                    <Col className="puesto" md='6'>
                    <p>{t("uxui_designer")}</p>
                    </Col>
                    <Col className="empresa" md='4'>
                    <p>Vrabo Technology Solutions</p>
                    </Col>
                    </Row>
                </Container>
            </Col>


            </Row>
            
        </Container>
        </>
    )
}

export default About;