import React from "react";
import Card from 'react-bootstrap/Card'; 
import { Link } from "react-router-dom";



function CarouselCards({titlecard, textcard, imgcard, projectlink}) {


  return (
    <Card >
    <Link to={projectlink}
    >
      <Card.Img variant="top" src={imgcard}/>
      <Card.Body>
      
        <Card.Title style={{fontSize:'medium'}}>{titlecard}</Card.Title>
        <Card.Text style={{fontSize:'small'}}>
        {textcard}
        </Card.Text>
       
      </Card.Body>
      </Link>
    </Card>
  );
}

export default CarouselCards;