import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import '../App.css';
import '../index.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import logo from '../logo.svg';



function NavBar({themeSelect, theme, lngSelect}) {
  const { t } = useTranslation()
  return (
    <Navbar style={{color: 'var(--background)'}} expand="lg" sticky="top">
      <Container >
      <Nav className="me-auto">
      <Navbar.Brand> <NavLink to="/">
        <img
              src={logo}
              width="50"
              height="50"
              className="d-inline-block align-top svgBrand"
              alt="Arantxa Ramirez logo"
              
            />
        </NavLink></Navbar.Brand>
      <NavDropdown title={t("navbar_projects")} renderMenuOnMount={true}>
              <Dropdown.Item as={NavLink} to="/projects/Gwi">GWI</Dropdown.Item>
              <Dropdown.Item as={NavLink} to="/projects/GreenMister">Green Mister</Dropdown.Item>
              <NavLink className="dropdown-item" to="/projects/Opm">OPM</NavLink>
              <NavLink className="dropdown-item" to="/projects/Entrenarme">Entrenarme</NavLink>
            </NavDropdown>
            <NavLink className="nav-link" to="/about">{t("navbar_about")}</NavLink>
           </Nav>
           <Nav>
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav.Link href ="mailto: arantxa.rmz.95@gmail.com">{t("navbar_contact")}</Nav.Link>
           
        
        </Navbar.Collapse>
        <NavDropdown align="end" title={t('lang_select')} renderMenuOnMount={true}>
        {lngSelect.map(({ code, name }) => (
          
            <DropdownItem onClick={() => i18next.changeLanguage(code)}>{name}</DropdownItem>
         
        )
        )}
        </NavDropdown>

      
        
    
        
        <button className='selectTheme' onClick={themeSelect}></button>
        
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavBar;