import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import Slider from "react-slick";
import CarouselCards from "./card";
import gwi1 from '../assets/img-gwi-0.png';
import gm1 from '../assets/GM-img1.png';
import opm1 from '../assets/img-opm-0.png';
import ent1 from '../assets/img-ent-0.png';
import i18n from "i18next";



function Arrow(props) {
  const { className, style, onClick } = props;
  
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color:'var(--color-primary)'}}
      onClick={onClick}
    />
  );
}




export default class SimpleSlider extends Component {
  
    render() {
        var settings = {
            infinite: true,
            slidesToShow: 3,
            swipe:true,
            swipeToSlide:true,
            slidesToScroll: 1,
            speed: 500,
            nextArrow: <Arrow/>,
            prevArrow:<Arrow/>,
            draggable: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1
                }
              }
            ]
          };

        return (
          <div className="blockslider">
        
            <Slider {...settings}>
              <CarouselCards 
                    imgcard={gwi1}
                    titlecard="GWI"
                    projectlink='projects/Gwi'
                    textcard={i18n.t("uxui_design") + "\u00A0 \u00A0 \u2014 \u00A0 \u00A0 " + i18n.t("motion_graphics")}
                    draggable                   
                />        
              <div draggable>
              <CarouselCards 
                    imgcard={gm1}
                    projectlink='projects/GreenMister'
                    titlecard="GREEN MISTER"
                    textcard={i18n.t("uxui_design")}
                    draggable
                />
              </div>
              <div>
              <CarouselCards 
                    imgcard={opm1}
                    projectlink='projects/OPM'
                    titlecard="OPM"
                    textcard={i18n.t("uxui_design") + "\u00A0 \u00A0 \u2014 \u00A0 \u00A0 " + i18n.t("frontend_development")}
                    draggable
                />
              </div>
              <div>
              <CarouselCards 
                    imgcard={ent1}
                    projectlink='projects/Entrenarme'
                    titlecard="ENTRENARME"
                    textcard={i18n.t("uxui_design") + "\u00A0 \u00A0 \u2014 \u00A0 \u00A0 " + i18n.t("ui_animation")}
                    draggable
                />
              </div>
  
            </Slider>
          </div>
        );
      }
    }